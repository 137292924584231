import { useMemo } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { PageLoader } from '@/components/PageLoader/PageLoader';
import { useProjectCheckoutServices } from '@/modules/project-checkout/project-checkout-services.context.';
import { useCheckoutSession } from '@/services/api/checkout/checkout.service.hooks';

import { OnboardingSignin } from '../components/OnboardingSignin/OnboardingSignin';

export function OnboardingSigninContainer() {
  const { projectCheckoutRecoveryRepository } = useProjectCheckoutServices();
  const [searchParams] = useSearchParams();
  const latestCheckout = useMemo(
    () => projectCheckoutRecoveryRepository.getLatestCheckout(),
    [projectCheckoutRecoveryRepository],
  );
  const checkoutSessionId =
    searchParams.get('checkout-session-id') ??
    searchParams.get('checkoutSessionId') ??
    latestCheckout?.checkoutId ??
    null;
  const checkoutSession = useCheckoutSession(checkoutSessionId);

  const gmailSniperLink = useMemo(() => {
    if (!checkoutSession.data) {
      return null;
    }
    const email = checkoutSession.data.buyerEmail;
    if (email.endsWith('@gmail.com') || email.endsWith('@remento.co')) {
      const from = '@remento.co';
      return `https://mail.google.com/mail/u/${email}/#search/from:(${from})+in:anywhere+newer_than:1d`;
    }
    return null;
  }, [checkoutSession.data]);

  // Redirect to the start of the flow if no checkout session id
  // is present or if the id is invalid
  if (checkoutSessionId === null) {
    return <Navigate to="/checkout" />;
  }
  if (checkoutSession.error) {
    return <Navigate to="/checkout" />;
  }

  if (!checkoutSession.data) {
    return <PageLoader />;
  }

  return <OnboardingSignin email={checkoutSession.data.buyerEmail} gmailSniperLink={gmailSniperLink} />;
}

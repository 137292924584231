import { OnboardingSigninContainer } from '@/modules/onboarding/containers/OnboardingSignin.container';
import { ProjectCheckoutServicesProvider } from '@/modules/project-checkout/project-checkout-services.context.';
import { RementoPage } from '@/modules/routing';

export function OnboardingSigninPage() {
  return (
    <RementoPage type="default">
      <ProjectCheckoutServicesProvider>
        <OnboardingSigninContainer />
      </ProjectCheckoutServicesProvider>
    </RementoPage>
  );
}
